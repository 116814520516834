exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-branze-js": () => import("./../../../src/pages/branze.js" /* webpackChunkName: "component---src-pages-branze-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cooperation-js": () => import("./../../../src/pages/cooperation.js" /* webpackChunkName: "component---src-pages-cooperation-js" */),
  "component---src-pages-depots-js": () => import("./../../../src/pages/depots.js" /* webpackChunkName: "component---src-pages-depots-js" */),
  "component---src-pages-firma-js": () => import("./../../../src/pages/firma.js" /* webpackChunkName: "component---src-pages-firma-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koszyk-jsx": () => import("./../../../src/pages/koszyk.jsx" /* webpackChunkName: "component---src-pages-koszyk-jsx" */),
  "component---src-pages-miejscowosci-js": () => import("./../../../src/pages/miejscowosci.js" /* webpackChunkName: "component---src-pages-miejscowosci-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-poznaj-nas-js": () => import("./../../../src/pages/poznaj-nas.js" /* webpackChunkName: "component---src-pages-poznaj-nas-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-depot-js": () => import("./../../../src/templates/depot.js" /* webpackChunkName: "component---src-templates-depot-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-specification-js": () => import("./../../../src/templates/specification.js" /* webpackChunkName: "component---src-templates-specification-js" */)
}

